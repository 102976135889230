<!-- 个人 首页 -->
<template>
  <div>
    <el-card class="box-card xtxiaox">
      <el-tag effect="dark">通知公告</el-tag>
      赣州经开区人才信息管理系统
    </el-card>

    <div class="hr-10"></div>
    <el-button @click="clickMenu" v-show="isMobile">修改信息</el-button>
    <div class="hr-10"></div>
    <el-card class="box-card" v-model="gridData" v-loading="loading">
      <el-row>
        <el-col :span="8" class="flususeskk">
          <div class="useskk">
            <div>
              <img
                v-if="headPortrait"
                :src="headPortrait"
                width="136"
                height="168"
                alt
              />
              <img
                v-else
                src="@/assets/user.jpg"
                width="136"
                height="168"
                alt
              />
            </div>
            <div class="xinxss">
              <el-row>
                <div>
                  <h2>{{ gridData.name }}</h2>
                  <el-tag
                    size="mini"
                    type="success"
                    v-model="gridData.status"
                    v-if="status === 21"
                  >
                    已认证
                  </el-tag>
                  <el-tag
                    size="mini"
                    type="danger"
                    v-model="gridData.status"
                    v-else-if="status === 10"
                    >单位驳回
                  </el-tag>
                  <el-tag
                    size="mini"
                    type="danger"
                    v-model="gridData.status"
                    v-else-if="status === 20"
                    >管理员驳回
                  </el-tag>
                  <el-tag size="mini" v-model="gridData.status" v-else
                    >未认证</el-tag
                  >
                </div>
              </el-row>
              <el-row>
                <el-col id="examine-btn">
                  <el-button
                    type="primary"
                    class="text"
                    disabled
                    v-if="status === 21"
                    >已审核</el-button
                  >
                  <el-button
                    type="warning"
                    class="text"
                    v-else-if="status === 10"
                    @click="requestReject"
                  >
                    点击查看驳回理由
                  </el-button>
                  <el-button
                    type="warning"
                    class="text"
                    v-else-if="status === 20"
                    @click="requestReject"
                  >
                    点击查看驳回理由
                  </el-button>
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    class="text"
                    @click="clickMenu()"
                    v-else
                    >个人认证
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="16" class="flususeskk">
          <div id="steps">
            <el-steps
              :active="returnStatus"
              align-center
              style="padding: 50px 0;"
            >
              <el-step title="提交认证"></el-step>
              <el-step title="单位审核"></el-step>
              <el-step title="审核通过"></el-step>
            </el-steps>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="480px"
      :showClose="showClo"
      style="margin-top: 4%"
    >
      <span style="font-size: 18px"
        >您有
        <span>{{ number }}</span> 条最新申报的政策已兑现，请尽快反馈！</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="requestReceive">去反馈 >></el-button>
      </span>
    </el-dialog>

    <el-dialog title="驳回理由" :visible.sync="dialog" width="520px">
      <div class="fl">
        <span v-if="status === 20">管理员驳回: </span>
        <span v-if="status === 10">单位驳回: </span>
        {{ content }}
      </div>
      <div class="mt-16"></div>
      <el-button
        type="primary"
        style="color:#fff"
        @click="$router.push('/probate')"
        >修改个人认证信息</el-button
      >
      <el-button @click="dialog = false">关闭</el-button>
    </el-dialog>
  </div>
</template>

<script>
import configDate from "../../../utils/config";

export default {
  //inject: ["reload"],
  data() {
    return {
      isMobile: false,
      headPortrait: "",
      showClo: false,
      loading: false,
      dialogVisible: false,
      fileurl: configDate.fileurl,
      content: "",
      dialog: false,
      id: "",
      status: 0,
      returnStatus: 0,
      state: "",
      gridData: "",
      tableData: "",
      userid: "",
      number: 0,
      newNumber: [],
      tableNewData: [],
      newId: this.$route.query.id,
    };
  },
  created() {
    this.id = sessionStorage.getItem("ID");
    this.getDate(); //初始化数据
    this.userid = sessionStorage.getItem("ID");
    this.getNewDate(); //初始化数据
  },
  mounted() {
    if (this.commonFunction._isMobile()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  methods: {
    getDate() {
      const self = this;
      this.$axios
        .get("/talent/detail?id=" + this.id)
        .then(function(res) {
          if (res.data.status === 200) {
            self.status = res.data.data.status;
            self.gridData = res.data.data;
            self.state = res.data.data.status;
            //2是提交 11时单位通过  21时管理员通过
            //10 单位驳回 20 管理员驳回
            if (self.state === 2) {
              self.returnStatus = 1;
            } else if (self.state === 11) {
              self.returnStatus = 2;
            } else if (self.state === 10) {
              self.returnStatus = 1;
            } else if (self.state === 21) {
              self.returnStatus = 3;
            } else if (self.state === 20) {
              self.returnStatus = 1;
            } else {
              self.returnStatus = 0;
            }
            res.data.data.files.forEach((item) => {
              if (item.memo === "免冠工作照") {
                self.headPortrait = configDate.fileurl + item.path;
              }
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //  驳回理由
    requestReject() {
      this.dialog = true;
      this.$axios
        .get("/talent/detail?id=" + sessionStorage.getItem("ID"))
        .then((response) => {
          if (response.data.status === 200) {
            this.content = response.data.data.memo;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //  查询是否有申报政策通过
    getNewDate() {
      const self = this;
      this.$axios
        .get("/affair/page", {
          params: {
            userId: this.userid,
          },
        })
        .then(function(res) {
          console.log(res);
          self.tableNewData = res.data.data.records;
          for (let i = 0; i < res.data.data.records.length; i++) {
            if (
              self.tableNewData[i].currentStatus === 21 &&
              self.tableNewData[i].complete === 0
            ) {
              self.newNumber.push(self.tableNewData[i]);
              if (self.newId === "talent") {
                self.dialogVisible = true;
              }
            }
          }
          self.number = self.newNumber.length;
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    requestReceive() {
      this.$router.push({ name: "declarelist" });
    },

    tolink() {},
    mounted() {
      //this.reload();
    },
    clickMenu() {
      this.$store.commit("selectMenu", {
        icon: "el-icon-setting",
        label: "认证管理",
        path: "/probate",
      });
      this.$router.push("/probate");
    },
  },
  computed: {
    // 控制显示的内容
    iSBoolean() {
      return function(value1, value2) {
        return value1 === value2;
      };
    },
  },
};
</script>

<style lang="less" scoped>
.useskk {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .xinxss {
    width: 100%;
    /*line-height: 70px;*/
    text-align: center;
    margin-left: 120px;

    /*span {*/
    /*    background: #eff0f4;*/
    /*    padding: 5px 10px;*/
    /*    border-radius: 12px;*/
    /*    margin: 0 0 0 5px;*/
    /*}*/

    h2 {
      margin-top: 24px;
      display: inline-block;
      padding-right: 16px;
    }

    .renzhen {
      a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

#authentication-btn {
  margin-top: 32px;
}

.xtxiaox {
  text-align: left;
}

#examine-btn {
  margin-top: 24px;
}
.text {
  color: #ffffff;
}
@import "../../../styles/common_qyrc.less";
</style>
